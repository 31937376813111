define("ember-i18n/config/zh", ["exports", "ember-i18n/config/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    rtl: false,
    pluralForm() /* n */{
      return _constants.OTHER;
    }
  };
});