define("@zestia/ember-select-box/components/native-select-box/option/index", ["exports", "@ember/component", "@glimmer/component", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/shared/api", "@zestia/ember-select-box/utils/component/value", "@zestia/ember-select-box/utils/shared/selected", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _element, _lifecycle, _api, _value, _selected, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <option
    class="select-box__option"
    selected={{this.isSelected}}
    value={{this.value}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{did-update this.handleUpdatedValue @value}}
    {{will-destroy this.handleDestroyElement}}
  >
    {{~yield this.api~}}
  </option>
  */
  {
    "id": "FY9wA67y",
    "block": "[[[11,\"option\"],[24,0,\"select-box__option\"],[16,\"selected\",[30,0,[\"isSelected\"]]],[16,2,[30,0,[\"value\"]]],[17,1],[4,[38,0],[[30,0,[\"handleInsertElement\"]]],null],[4,[38,1],[[30,0,[\"handleUpdatedValue\"]],[30,2]],null],[4,[38,2],[[30,0,[\"handleDestroyElement\"]]],null],[12],[18,3,[[30,0,[\"api\"]]]],[13]],[\"&attrs\",\"@value\",\"&default\"],false,[\"did-insert\",\"did-update\",\"will-destroy\",\"yield\"]]",
    "moduleName": "@zestia/ember-select-box/components/native-select-box/option/index.hbs",
    "isStrictMode": false
  });
  let NativeSelectBoxOption = _exports.default = (_class = class NativeSelectBoxOption extends _component2.default {
    get api() {
      return (0, _api.default)(this, ['element', 'index', 'isFulfilled', 'isPending', 'isRejected', 'isSelected', 'isSettled', 'value']);
    }
    get index() {
      return this.args.selectBox ? this.args.selectBox.option.indexOf(this) : -1;
    }
    get isSelected() {
      return (0, _selected.default)(this);
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "element", null);
      _defineProperty(this, "previousValue", null);
      _defineProperty(this, "stableAPI", {});
      _defineProperty(this, "valueId", 0);
      _initializerDefineProperty(this, "isFulfilled", _descriptor, this);
      _initializerDefineProperty(this, "isPending", _descriptor2, this);
      _initializerDefineProperty(this, "isRejected", _descriptor3, this);
      _initializerDefineProperty(this, "isSettled", _descriptor4, this);
      _initializerDefineProperty(this, "value", _descriptor5, this);
      (0, _value.receiveValue)(this);
    }
    handleInsertElement(element) {
      (0, _element.registerElement)(this, element);
      (0, _lifecycle._insertComponent)(this);
    }
    handleUpdatedValue() {
      (0, _value.receiveValue)(this);
    }
    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
      (0, _lifecycle._destroyComponent)(this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleUpdatedValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdatedValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NativeSelectBoxOption);
});