define("ember-i18n/utils/get-locales", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getLocales;
  /* globals require */

  const matchKey = '/locales/(.+)/translations$';
  function getLocales() {
    return Object.keys(require.entries).reduce((locales, module) => {
      var match = module.match(matchKey);
      if (match) {
        locales.pushObject(match[1]);
      }
      return locales;
    }, (0, _array.A)()).sort();
  }
});