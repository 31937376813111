define("ember-autofocus-modifier/modifiers/autofocus", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SELECTOR = 'input:not([disabled]),textarea:not([disabled])';
  var _default = _exports.default = (0, _emberModifier.modifier)(function autofocus(element, _ref) {
    let [selector = DEFAULT_SELECTOR] = _ref;
    const childElement = element.querySelector(selector);
    if (childElement) {
      childElement.focus();
    } else {
      element.focus();
    }
  });
});