define("ember-batcher/batcher", ["exports", "ember-test-waiters"], function (_exports, _emberTestWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mutateDOM = mutateDOM;
  _exports.readDOM = readDOM;
  _exports.visibilityChange = void 0;
  const IS_BROWSER = typeof window === 'object' && typeof document === 'object';
  const SCHEDULE_MACROTASK = callback => setTimeout(callback);
  const readDOMWaiter = (0, _emberTestWaiters.buildWaiter)('ember-batcher: readDOM');
  const mutateDOMWaiter = (0, _emberTestWaiters.buildWaiter)('ember-batcher: mutateDOM');
  const reads = [];
  const mutations = [];
  let visibilityChange = () => {};
  _exports.visibilityChange = visibilityChange;
  let running = false;
  let scheduleFnExecuted = false;
  const rafRace = callback => {
    let executeCallback = () => {
      if (!scheduleFnExecuted) {
        scheduleFnExecuted = true;
        callback();
      }
    };
    setTimeout(executeCallback, 20);
    return requestAnimationFrame(executeCallback);
  };
  const scheduleFn = typeof window === 'object' && typeof window.requestAnimationFrame === 'function' ? rafRace : SCHEDULE_MACROTASK;
  if (false /* DEBUG */ && IS_BROWSER) {
    _exports.visibilityChange = visibilityChange = function () {
      let hidden = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document.hidden;
      let hasQueuedWork = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => reads.length > 0 && mutations.length > 0;
      return () => {
        if (hidden && hasQueuedWork()) {
          throw new Error("Your browser tab is running in the background. ember-batcher's execution is not guaranteed in this environment");
        }
      };
    };
    document.addEventListener('visibilitychange', visibilityChange());
  }
  function run() {
    if (!running) {
      running = true;
      scheduleFn(() => {
        let i, l;
        for (i = 0, l = reads.length; i < l; i++) {
          let [token, readTask] = reads.pop();
          readTask();
          readDOMWaiter.endAsync(token);
        }
        for (i = 0, l = mutations.length; i < l; i++) {
          let [token, mutateTask] = mutations.pop();
          mutateTask();
          mutateDOMWaiter.endAsync(token);
        }
        running = false;
        scheduleFnExecuted = false;
        if (mutations.length > 0 || reads.length > 0) {
          run();
        }
      });
    }
  }

  /**
   * Provides a mechanism to group DOM reads to minimize layout thrashing.
   *
   * @param readTask The function to call as part of the reads batch.
   */
  function readDOM(readTask) {
    let token = readDOMWaiter.beginAsync();
    reads.unshift([token, readTask]);
    run();
  }

  /**
   * Provides a mechanism to group DOM mutations to minimize layout thrashing.
   *
   * @param mutationTask The function to call as part of the mutations batch.
   */
  function mutateDOM(mutationTask) {
    let token = mutateDOMWaiter.beginAsync();
    mutations.unshift([token, mutationTask]);
    run();
  }
});