define("ember-i18n/utils/i18n/compile-template", ["exports", "@ember/template", "@ember/object", "ember"], function (_exports, _template, _object, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = compileTemplate;
  const escapeExpression = _ember.default.Handlebars.Utils.escapeExpression;
  const tripleStache = /\{\{\{\s*(.*?)\s*\}\}\}/g;
  const doubleStache = /\{\{\s*(.*?)\s*\}\}/g;

  // @public
  //
  // Compile a translation template.
  //
  // To override this, define `util:i18n/compile-template` with
  // the signature
  // `Function(String, Boolean) -> Function(Object) -> String`.
  function compileTemplate(template) {
    let rtl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return function renderTemplate(data) {
      const result = template.replace(tripleStache, (i, match) => (0, _object.get)(data, match)).replace(doubleStache, (i, match) => escapeExpression((0, _object.get)(data, match)));
      const wrapped = rtl ? `\u202B${result}\u202C` : result;
      return (0, _template.htmlSafe)(wrapped);
    };
  }
});