define("ember-autolinkify/helpers/linkify", ["exports", "@ember/component/helper", "autolinker"], function (_exports, _helper, _autolinker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linkify = linkify;
  function linkify(text, options) {
    return _autolinker.default.link(text, options);
  }
  var _default = _exports.default = (0, _helper.helper)(function (_ref, options) {
    let [text] = _ref;
    return linkify(text, options);
  });
});