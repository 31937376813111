define("@zestia/ember-select-box/utils/select-box/search", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cancelSearch = cancelSearch;
  _exports.maybeSearch = maybeSearch;
  _exports.search = search;
  function maybeSearch(selectBox, query) {
    if (!isSearchable(selectBox)) {
      return;
    }
    if (selectBox.searchDelayTime === 0) {
      attemptSearch(selectBox, query);
    } else {
      debouncedSearchAttempt(selectBox, query);
    }
  }
  function search(selectBox, query) {
    const searchId = startSearch(selectBox);
    setTimeout(() => checkSlowSearch(selectBox), selectBox.searchSlowTime);
    return (0, _rsvp.resolve)(runSearch(selectBox, query)).then(result => {
      handleSearch(selectBox, searchId, query, result, false);
    }).catch(error => {
      handleSearch(selectBox, searchId, query, error, true);
    }).finally(() => {
      finishSearch(selectBox);
    });
  }
  function cancelSearch(selectBox) {
    incrementSearch(selectBox);
    finishSearch(selectBox);
  }
  function debouncedSearchAttempt(selectBox, query) {
    (0, _runloop.debounce)(attemptSearch, selectBox, query, selectBox.searchDelayTime);
  }
  function attemptSearch(selectBox, query) {
    query = `${query}`.trim();
    if (!queryOK(selectBox, query)) {
      return;
    }
    search(selectBox, query);
  }
  function handleSearch(selectBox, searchId, query, result, erred) {
    if (searchId < selectBox.searchId) {
      return;
    }
    if (erred) {
      searchFailed(selectBox, query, result);
    } else {
      searchSucceeded(selectBox, query, result);
    }
  }
  function startSearch(selectBox) {
    selectBox.isSearching = true;
    return incrementSearch(selectBox);
  }
  function finishSearch(selectBox) {
    selectBox.isSearching = false;
    selectBox.isSlowSearch = false;
  }
  function incrementSearch(selectBox) {
    return ++selectBox.searchId;
  }
  function isSearchable(selectBox) {
    return typeof selectBox.args.onSearch === 'function';
  }
  function queryOK(selectBox, query) {
    return query.length >= selectBox.searchMinChars;
  }
  function checkSlowSearch(selectBox) {
    selectBox.isSlowSearch = selectBox.isSearching;
  }
  function runSearch(selectBox, query) {
    var _selectBox$args$onSea, _selectBox$args;
    return (_selectBox$args$onSea = (_selectBox$args = selectBox.args).onSearch) === null || _selectBox$args$onSea === void 0 ? void 0 : _selectBox$args$onSea.call(_selectBox$args, query, selectBox.api);
  }
  function searchSucceeded(selectBox, query, result) {
    var _selectBox$args$onSea2, _selectBox$args2;
    (_selectBox$args$onSea2 = (_selectBox$args2 = selectBox.args).onSearched) === null || _selectBox$args$onSea2 === void 0 || _selectBox$args$onSea2.call(_selectBox$args2, result, query, selectBox.api);
  }
  function searchFailed(selectBox, query, error) {
    var _selectBox$args$onSea3, _selectBox$args3;
    (_selectBox$args$onSea3 = (_selectBox$args3 = selectBox.args).onSearchError) === null || _selectBox$args$onSea3 === void 0 || _selectBox$args$onSea3.call(_selectBox$args3, error, query, selectBox.api);
  }
});