define("ember-i18n/config/he", ["exports", "ember-i18n/config/en"], function (_exports, _en) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    rtl: true,
    pluralForm: _en.default.pluralForm
  };
});