define("@zestia/ember-select-box/components/select-box/input/index", ["exports", "@ember/component", "@glimmer/component", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/select-box/input/keyboard", "@zestia/ember-select-box/utils/shared/id", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _lifecycle, _element, _keyboard, _id, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-down-event-binding no-element-event-actions }}
  {{!
  Issue affects attributes `type`, `onclick` and `value`
  https://github.com/emberjs/rfcs/issues/497
  }}
  <input
    aria-multiline="false"
    aria-controls={{@selectBox.options.id}}
    autocomplete="off"
    class="select-box__input"
    disabled={{@selectBox.isDisabled}}
    id={{this.id}}
    role="searchbox"
    type={{if @type @type "text"}}
    onclick={{@onclick}}
    value={{@value}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{will-destroy this.handleDestroyElement}}
    {{on "input" this.handleInput}}
    {{on "keydown" this.handleKeyDown}}
  />
  */
  {
    "id": "JhA9NA6v",
    "block": "[[[11,\"input\"],[24,\"aria-multiline\",\"false\"],[16,\"aria-controls\",[30,1,[\"options\",\"id\"]]],[24,\"autocomplete\",\"off\"],[24,0,\"select-box__input\"],[16,\"disabled\",[30,1,[\"isDisabled\"]]],[16,1,[30,0,[\"id\"]]],[24,\"role\",\"searchbox\"],[16,4,[52,[30,2],[30,2],\"text\"]],[16,\"onclick\",[30,3]],[16,2,[30,4]],[17,5],[4,[38,1],[[30,0,[\"handleInsertElement\"]]],null],[4,[38,2],[[30,0,[\"handleDestroyElement\"]]],null],[4,[38,3],[\"input\",[30,0,[\"handleInput\"]]],null],[4,[38,3],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[12],[13]],[\"@selectBox\",\"@type\",\"@onclick\",\"@value\",\"&attrs\"],false,[\"if\",\"did-insert\",\"will-destroy\",\"on\"]]",
    "moduleName": "@zestia/ember-select-box/components/select-box/input/index.hbs",
    "isStrictMode": false
  });
  let SelectBoxInput = _exports.default = (_class = class SelectBoxInput extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "element", null);
    }
    get id() {
      return (0, _id.default)(this);
    }
    handleInsertElement(element) {
      (0, _element.registerElement)(this, element);
      (0, _lifecycle._insertComponent)(this);
    }
    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
      (0, _lifecycle._destroyComponent)(this);
    }
    handleInput(e) {
      (0, _keyboard.input)(this, e);
    }
    handleKeyDown(e) {
      (0, _keyboard.keyDown)(this, e);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectBoxInput);
});