define("ember-i18n/config/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ZERO = _exports.TWO = _exports.OTHER = _exports.ONE = _exports.MANY = _exports.FEW = void 0;
  const ZERO = _exports.ZERO = 'zero';
  const ONE = _exports.ONE = 'one';
  const TWO = _exports.TWO = 'two';
  const FEW = _exports.FEW = 'few';
  const MANY = _exports.MANY = 'many';
  const OTHER = _exports.OTHER = 'other';
});