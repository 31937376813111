define("@zestia/ember-select-box/utils/select-box/option/activate", ["exports", "@zestia/ember-select-box/utils/component/filter", "@zestia/ember-select-box/utils/component/scroll-into-view"], function (_exports, _filter, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._activateOption = _activateOption;
  _exports.activateNextOption = activateNextOption;
  _exports.activateOption = activateOption;
  _exports.activateOptionAtIndex = activateOptionAtIndex;
  _exports.activateOptionForKeyCode = activateOptionForKeyCode;
  _exports.activateOptionForValue = activateOptionForValue;
  _exports.activatePreviousOption = activatePreviousOption;
  _exports.deactivateOptions = deactivateOptions;
  const {
    fromCharCode
  } = String;
  function _activateOption(option) {
    var _option$args$_onActiv, _option$args;
    if (option.args.selectBox.activeOption === option) {
      return;
    }
    (_option$args$_onActiv = (_option$args = option.args)._onActivate) === null || _option$args$_onActiv === void 0 || _option$args$_onActiv.call(_option$args, option);
  }
  function activatedOption(option) {
    var _option$args$onActiva, _option$args2;
    (_option$args$onActiva = (_option$args2 = option.args).onActivate) === null || _option$args$onActiva === void 0 || _option$args$onActiva.call(_option$args2, option.value, option.args.selectBox.api);
  }
  function activateOption(selectBox, option, config) {
    activateOptionAtIndex(selectBox, option.index, config);
  }
  function deactivateOptions(selectBox) {
    _setActiveOptionIndex(selectBox, -1);
  }
  function focusOption(option) {
    option.element.focus();
  }
  function _setActiveOptionIndex(selectBox, index) {
    selectBox.activeOptionIndex = index;
  }
  function setActiveOptionIndex(selectBox, index) {
    const under = index < 0;
    const over = index > selectBox.option.length - 1;
    if (under || over) {
      return;
    }
    _setActiveOptionIndex(selectBox, index);
  }
  function activateOptionAtIndex(selectBox, index, config) {
    setActiveOptionIndex(selectBox, index);
    const option = selectBox.activeOption;
    if (!option) {
      return;
    }
    (0, _scrollIntoView.maybeScrollIntoView)(option, config);
    focusOption(option);
    activatedOption(option);
  }
  function activateNextOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex + 1, {
      scrollIntoView: true,
      ...config
    });
  }
  function activatePreviousOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex - 1, {
      scrollIntoView: true,
      ...config
    });
  }
  function activateOptionForValue(selectBox, value, config) {
    const option = selectBox.option.find(option => option.value === value);
    if (!option) {
      return;
    }
    activateOption(selectBox, option, {
      scrollIntoView: true,
      ...config
    });
  }
  function activateOptionForKeyCode(selectBox, keyCode, config) {
    const char = fromCharCode(keyCode);
    if (!char) {
      return;
    }
    const option = optionForChar(selectBox, char);
    if (!option) {
      return;
    }
    activateOption(selectBox, option, {
      scrollIntoView: true,
      ...config
    });
  }
  function optionForChar(selectBox, char) {
    var _selectBox$charState;
    const prev = (_selectBox$charState = selectBox.charState) !== null && _selectBox$charState !== void 0 ? _selectBox$charState : {
      chars: '',
      index: 0
    };
    const prevChar = prev.chars.substring(prev.chars.length - 1);
    const repeatedChar = char === prevChar;
    const chars = `${prev.chars}${char}`;
    const registeredOptions = selectBox.option;
    let options = (0, _filter.filterComponentsByTextContent)(registeredOptions, chars);
    let index = 0;
    let option = null;
    if (repeatedChar) {
      index = prev.index + 1;
      options = (0, _filter.filterComponentsByTextContent)(registeredOptions, prevChar);
      option = options[index];
    }
    if (!option) {
      index = 0;
      option = options[index];
    }
    if (prev.timer) {
      clearTimeout(prev.timer);
    }
    const timer = setTimeout(() => selectBox.charState = null, 1000);
    const next = {
      chars,
      index,
      timer
    };
    selectBox.charState = next;
    return option;
  }
});