define("ember-lines-to-html/helpers/lines-to-html", ["exports", "@ember/component/helper", "@ember/template", "dompurify"], function (_exports, _helper, _template, _dompurify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linesToHtml = linesToHtml;
  _exports.probablyHtml = probablyHtml;
  _exports.purify = void 0;
  const purify = _exports.purify = (0, _dompurify.default)(window);
  function probablyHtml(text) {
    return /^<(p|div|h1|h2|h3|h4|h5|h6|ul|ol|dl|pre|blockquote|table)[\s\S]*>/i.test(text);
  }
  function linesToHtml(text) {
    let {
      maxLines = null,
      visualizeBreaks = false
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!text) {
      return '';
    }
    if (probablyHtml(text)) {
      return (0, _template.htmlSafe)(purify.sanitize(text, {
        ADD_ATTR: ['target']
      }));
    }

    // normalize
    text = purify.sanitize(text, {
      ADD_ATTR: ['target']
    }).replace(/(\r\n|\n\r|\r)/g, '\n');
    let clipped = false;
    if (maxLines) {
      const lines = text.split('\n');
      if (lines.length > maxLines) {
        text = lines.slice(0, maxLines).join('\n');
        clipped = true;
      }
    }
    let html = text.replace(/\n{2}/g, '</p><p>');
    html = html.replace(/\n/g, '<br />');
    html = '<p>' + html + '</p>';
    if (clipped) {
      html += '<div class="u-lines-to-html-clipped">...</div>';
    }
    return (0, _template.htmlSafe)(`<div class="u-lines-to-html">${visualizeBreaks ? html.replace(/<br \/>/g, '<span class="ml-0.5 text-2xs font-medium text-gray-400 bg-gray-100 rounded border border-gray-200">[br]</span><br />') : html}</div>`);
  }
  var _default = _exports.default = (0, _helper.helper)(function (_ref, _ref2) {
    let [text] = _ref;
    let {
      maxLines,
      visualizeBreaks
    } = _ref2;
    return linesToHtml(text, {
      maxLines,
      visualizeBreaks
    });
  });
});