define("ember-lines-to-html/helpers/first-line", ["exports", "@ember/component/helper", "ember-lines-to-html/helpers/lines-to-html"], function (_exports, _helper, _linesToHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.firstLine = firstLine;
  function firstLine(text) {
    var _text$split;
    if (!text) {
      return '';
    }
    if ((0, _linesToHtml.probablyHtml)(text)) {
      const html = _linesToHtml.purify.sanitize(text, {
        ADD_ATTR: ['target']
      });
      return new DOMParser().parseFromString(html, 'text/html').body.firstElementChild.textContent.trim();
    }
    return ((_text$split = text.split('\n')) === null || _text$split === void 0 || (_text$split = _text$split[0]) === null || _text$split === void 0 ? void 0 : _text$split.trim()) || '';
  }
  var _default = _exports.default = (0, _helper.helper)(function (_ref) {
    let [text] = _ref;
    return firstLine(text);
  });
});