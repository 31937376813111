define("@zestia/ember-select-box/utils/native-select-box/selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSelectedValue = getSelectedValue;
  function getSelectedValue(selectBox) {
    const selectedValues = getSelectedValues(selectBox);
    if (selectBox.isMultiple) {
      return selectedValues;
    } else {
      return selectedValues[0];
    }
  }
  function getSelectedValues(selectBox) {
    const registered = getRegisteredSelectedValues(selectBox);
    const unregistered = getUnregisteredSelectedValues(selectBox);
    if (registered.length > 0) {
      return registered;
    } else {
      return unregistered;
    }
  }
  function getRegisteredSelectedValues(selectBox) {
    return selectBox.option.filter(option => option.element.selected).map(option => option.value);
  }
  function getUnregisteredSelectedValues(selectBox) {
    return [...selectBox.element.querySelectorAll('option:checked')].map(element => element.value);
  }
});