define("@zestia/ember-select-box/helpers/-register-components", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assign
  } = Object;

  // Temporarily using a global helper until local helpers are supported
  var _default = _exports.default = (0, _helper.helper)(function (_ref, components) {
    let [component] = _ref;
    assign(component, components);
  });
});