define("ember-template-compiler/lib/plugins/transform-quoted-bindings-into-just-bindings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformQuotedBindingsIntoJustBindings;
  function /* env */
  transformQuotedBindingsIntoJustBindings() {
    return {
      name: 'transform-quoted-bindings-into-just-bindings',
      visitor: {
        ElementNode(node) {
          var styleAttr = getStyleAttr(node);
          if (!validStyleAttr(styleAttr)) {
            return;
          }
          styleAttr.value = styleAttr.value.parts[0];
        }
      }
    };
  }
  function validStyleAttr(attr) {
    if (!attr) {
      return false;
    }
    var value = attr.value;
    if (!value || value.type !== 'ConcatStatement' || value.parts.length !== 1) {
      return false;
    }
    var onlyPart = value.parts[0];
    return onlyPart.type === 'MustacheStatement';
  }
  function getStyleAttr(node) {
    var attributes = node.attributes;
    for (var attribute of attributes) {
      if (attribute.name === 'style') {
        return attribute;
      }
    }
    return undefined;
  }
});