define("ember-concurrency/-private/task-decorators", ["exports", "@ember/object", "ember-concurrency/-private/task-factory", "ember-concurrency/-private/utils"], function (_exports, _object, _taskFactory, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskGroup = _exports.task = _exports.restartableTaskGroup = _exports.restartableTask = _exports.lastValue = _exports.keepLatestTaskGroup = _exports.keepLatestTask = _exports.enqueueTaskGroup = _exports.enqueueTask = _exports.dropTaskGroup = _exports.dropTask = void 0;
  function taskFromPropertyDescriptor(target, key, descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let {
      initializer,
      get,
      value
    } = descriptor;
    let taskFn;
    if (initializer) {
      taskFn = initializer.call(undefined);
    } else if (get) {
      taskFn = get.call(undefined);
    } else if (value) {
      taskFn = value;
    }
    taskFn.displayName = `${key} (task)`;
    let tasks = new WeakMap();
    let options = params[0] || {};
    let factory = new _taskFactory.TaskFactory(key, taskFn, options);
    factory._setupEmberKVO(target);
    return {
      get() {
        let task = tasks.get(this);
        if (!task) {
          task = factory.createTask(this);
          tasks.set(this, task);
        }
        return task;
      }
    };
  }
  function taskGroupPropertyDescriptor(target, key, _descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let taskGroups = new WeakMap();
    let options = params[0] || {};
    let factory = new _taskFactory.TaskFactory(key, null, options);
    return {
      get() {
        let task = taskGroups.get(this);
        if (!task) {
          task = factory.createTaskGroup(this);
          taskGroups.set(this, task);
        }
        return task;
      }
    };
  }

  // Cribbed from @ember-decorators/utils
  function isFieldDescriptor(possibleDesc) {
    let [target, key, desc] = possibleDesc;
    return possibleDesc.length === 3 && typeof target === 'object' && target !== null && typeof key === 'string' && (typeof desc === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility ???
    ;
  }
  function decoratorWithParams(descriptorFn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }
      if (isFieldDescriptor(params)) {
        return descriptorFn(...params);
      } else {
        return function () {
          for (var _len2 = arguments.length, desc = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            desc[_key2] = arguments[_key2];
          }
          return descriptorFn(...desc, params);
        };
      }
    };
  }
  function createDecorator(fn) {
    let baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return decoratorWithParams(function (target, key, descriptor) {
      let [userOptions] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      let mergedOptions = Object.assign({}, {
        ...baseOptions,
        ...userOptions
      });
      return fn(target, key, descriptor, [mergedOptions]);
    });
  }
  const lastValue = _exports.lastValue = decoratorWithParams(function (target, key, descriptor) {
    let [taskName] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    const {
      initializer
    } = descriptor;
    delete descriptor.initializer;
    if (_utils.USE_TRACKED) {
      return {
        get() {
          let lastInstance = this[taskName].lastSuccessful;
          if (lastInstance) {
            return lastInstance.value;
          }
          if (initializer) {
            return initializer.call(this);
          }
          return undefined;
        }
      };
    } else {
      let cp = (0, _object.computed)(`${taskName}.lastSuccessful`, function () {
        let lastInstance = (0, _object.get)(this, `${taskName}.lastSuccessful`);
        if (lastInstance) {
          // eslint-disable-next-line ember/no-get
          return (0, _object.get)(lastInstance, 'value');
        }
        if (initializer) {
          return initializer.call(this);
        }
        return undefined;
      });
      return cp(target, key, descriptor);
    }
  });

  /**
   * A Task is a cancelable, restartable, asynchronous operation that
   * is driven by a generator function. Tasks are automatically canceled
   * when the object they live on is destroyed (e.g. a Component
   * is unrendered).
   *
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * By default, tasks have no concurrency constraints
   * (multiple instances of a task can be running at the same time)
   * but much of a power of tasks lies in proper usage of Task Modifiers
   * that you can apply to a task.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options
   * @param {string|string[]} [options.cancelOn] Events to cancel task on. Applies only to `&#64;ember/component`
   * @param {boolean} [options.enqueue] Sets `enqueue` modifier on task if `true`
   * @param {boolean} [options.evented] Enables [task lifecycle events](/docs/advanced/lifecycle-events) for this Task, if `true`
   * @param {boolean} [options.debug] Enables task debugging if `true`
   * @param {boolean} [options.drop] Sets `drop` modifier on task if `true`
   * @param {string} [options.group] Associates task with the group specified
   * @param {boolean} [options.keepLatest] Sets `keepLatest` modifier on task if `true`
   * @param {number} [options.maxConcurrency] Sets the maximum number of running task instances for the task
   * @param {string|string[]} [options.observes] Properties to watch and cause task to be performed when they change
   * @param {string|string[]} [options.on] Events to perform task on. Applies only to `&#64;ember/component`
   * @param {function?} [options.onState] Callback to use for state tracking. May be set to `null` to disable state tracking.
   * @param {boolean} [options.restartable] Sets `restartable` modifier on task if `true`
   * @return {Task}
   */
  const task = _exports.task = createDecorator(taskFromPropertyDescriptor);

  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, dropTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;dropTask({ cancelOn: 'click' })
   *   *myDropTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  const dropTask = _exports.dropTask = createDecorator(taskFromPropertyDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, enqueueTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;enqueueTask({ cancelOn: 'click' })
   *   *myEnqueueTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  const enqueueTask = _exports.enqueueTask = createDecorator(taskFromPropertyDescriptor, {
    enqueue: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, keepLatestTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;keepLatestTask({ cancelOn: 'click' })
   *   *myKeepLatestTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  const keepLatestTask = _exports.keepLatestTask = createDecorator(taskFromPropertyDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, restartableTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;restartableTask({ cancelOn: 'click' })
   *   *myRestartableTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  const restartableTask = _exports.restartableTask = createDecorator(taskFromPropertyDescriptor, {
    restartable: true
  });

  /**
   * "Task Groups" provide a means for applying
   * task modifiers to groups of tasks. Once a {@linkcode Task} is declared
   * as part of a group task, modifiers like `drop` or `restartable`
   * will no longer affect the individual `Task`. Instead those
   * modifiers can be applied to the entire group.
   *
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import Component from '@glimmer/component';
   * import { task, taskGroup } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;taskGroup({ maxConcurrency: 5 }) chores;
   *
   *   &#64;task({ group: 'chores' })
   *   *mowLawn() {}
   *
   *   &#64;task({ group: 'chores' })
   *   *doDishes() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  const taskGroup = _exports.taskGroup = createDecorator(taskGroupPropertyDescriptor);

  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  const dropTaskGroup = _exports.dropTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  const enqueueTaskGroup = _exports.enqueueTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    enqueue: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  const keepLatestTaskGroup = _exports.keepLatestTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  const restartableTaskGroup = _exports.restartableTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    restartable: true
  });
});