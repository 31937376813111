define("@zestia/ember-select-box/utils/select-box/selection", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildSelection = buildSelection;
  const {
    isArray
  } = Array;
  function buildSelection(selectBox, value1) {
    const value2 = selectBox.value;
    const build = selectBox.args.onBuildSelection;
    if (typeof build === 'function') {
      return build(value1, value2);
    }
    return buildSelectionDefault(selectBox, value1, value2);
  }
  function buildSelectionDefault(selectBox, value1, value2) {
    let value = value1;
    if (selectBox.isMultiple && !isArray(value1)) {
      const temp = (0, _array.A)([...value2]);
      if (temp.includes(value1)) {
        temp.removeObject(value1);
      } else {
        temp.addObject(value1);
      }
      value = temp.toArray();
    }
    return value;
  }
});