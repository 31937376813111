define("@zestia/ember-select-box/components/select-box/group/index", ["exports", "@ember/component", "@glimmer/component", "@zestia/ember-select-box/utils/shared/id", "@ember/template-factory"], function (_exports, _component, _component2, _id, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="select-box__group"
    role="group"
    aria-labelledby={{this.labelId}}
    ...attributes
  >
    <div class="select-box__group-label" id={{this.labelId}}>
      {{~@label~}}
    </div>
    <div class="select-box__group-options">
      {{~yield~}}
    </div>
  </div>
  */
  {
    "id": "o+Z5gZwM",
    "block": "[[[11,0],[24,0,\"select-box__group\"],[24,\"role\",\"group\"],[16,\"aria-labelledby\",[30,0,[\"labelId\"]]],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"select-box__group-label\"],[15,1,[30,0,[\"labelId\"]]],[12],[1,[30,2]],[13],[1,\"\\n  \"],[10,0],[14,0,\"select-box__group-options\"],[12],[18,3,null],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@label\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@zestia/ember-select-box/components/select-box/group/index.hbs",
    "isStrictMode": false
  });
  class SelectBoxGroup extends _component2.default {
    get labelId() {
      return (0, _id.default)(this);
    }
  }
  _exports.default = SelectBoxGroup;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectBoxGroup);
});