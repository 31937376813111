define("ember-hotjar/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  function hotjar() {
    window.hj.q = window.hj.q || [];
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    window.hj.q.push(args);
  }
  if (!window.hj) {
    window.hj = hotjar;
  }
  function enabledForEnvironment(config) {
    let environment = config.hotjar.environment;
    if (typeof environment === 'string') {
      environment = environment.split(',');
    }
    if (!Array.isArray(environment)) {
      return config.environment !== 'development';
    }
    return environment.includes(config.environment);
  }
  function loadScript(src) {
    let script = document.createElement('script');
    script.async = 1;
    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
  function loadHotjar(hjid) {
    let hjsv = 6;
    window._hjSettings = {
      hjid,
      hjsv
    };
    loadScript(`https://static.hotjar.com/c/hotjar-${hjid}.js?sv=${hjsv}`);
    return window.hj;
  }
  function initialize(container, config) {
    if (!config.hotjar || !config.hotjar.id || !enabledForEnvironment(config)) {
      return;
    }
    container.lookup('service:hotjar').setup(loadHotjar(config.hotjar.id));
  }
});