define("@zestia/ember-select-box/components/select-box/selected-options/index", ["exports", "@ember/component", "@glimmer/component", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/shared/id", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _lifecycle, _id, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-invalid-interactive no-element-event-actions }}
  {{!
  Issue affects `onclick` attribute
  https://github.com/emberjs/rfcs/issues/497
  }}
  <div
    class="select-box__selected-options"
    id={{this.id}}
    onclick={{@onclick}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{will-destroy this.handleDestroyElement}}
  >
    {{~yield~}}
  </div>
  */
  {
    "id": "pmxgepxC",
    "block": "[[[11,0],[24,0,\"select-box__selected-options\"],[16,1,[30,0,[\"id\"]]],[16,\"onclick\",[30,1]],[17,2],[4,[38,0],[[30,0,[\"handleInsertElement\"]]],null],[4,[38,1],[[30,0,[\"handleDestroyElement\"]]],null],[12],[18,3,null],[13]],[\"@onclick\",\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "@zestia/ember-select-box/components/select-box/selected-options/index.hbs",
    "isStrictMode": false
  });
  let SelectBoxSelectedOptions = _exports.default = (_class = class SelectBoxSelectedOptions extends _component2.default {
    get id() {
      return (0, _id.default)(this);
    }
    handleInsertElement() {
      (0, _lifecycle._insertComponent)(this);
    }
    handleDestroyElement() {
      (0, _lifecycle._destroyComponent)(this);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectBoxSelectedOptions);
});