define("@zestia/ember-select-box/utils/component/scroll-into-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollIntoView;
  _exports.maybeScrollIntoView = maybeScrollIntoView;
  function maybeScrollIntoView(component) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!config.scrollIntoView) {
      return;
    }
    scrollIntoView(component);
  }
  function scrollIntoView(component) {
    component.element.scrollIntoView({
      block: 'nearest'
    });
  }
});