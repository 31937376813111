define("@zestia/ember-select-box/utils/registration/components", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerComponents = registerComponents;
  const {
    assign
  } = Object;
  function registerComponents(component) {
    return (0, _helper.helper)(function (_, components) {
      assign(component, components);
    });
  }
});