define("toggle-wrap/components/toggle-wrap", ["exports", "@ember/object/computed", "@ember/component", "toggle-wrap/templates/components/toggle-wrap", "ember-concurrency", "jquery"], function (_exports, _computed, _component, _toggleWrap, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const localStorage = window.localStorage;
  var _default = _exports.default = _component.default.extend({
    layout: _toggleWrap.default,
    animate: false,
    visible: false,
    startVisible: false,
    closedByDocument: false,
    hidden: (0, _computed.not)('visible'),
    init() {
      this._super(...arguments);
      this.set('visible', this.startVisible);
      if (this.persist) {
        let visible = JSON.parse(localStorage.getItem(this.persistKey()));
        if (typeof visible === 'boolean') {
          this.set('visible', visible);
        }
      }
    },
    toggle: (0, _emberConcurrency.task)(function () {
      var _this = this;
      let visible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return function* () {
        if (visible === null) {
          visible = !_this.visible;
        }
        _this.set('visible', visible);
        yield (0, _emberConcurrency.timeout)(1);
        if (visible && _this.closedByDocument) {
          let click = event => {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }
            let popup = (0, _jquery.default)(_this.element).find('.c-popup');
            if (popup.length && (popup.is(event.target) || popup.has(event.target).length)) {
              (0, _jquery.default)(document).one('click', click);
              return;
            }
            _this.toggle.perform(false);
          };
          (0, _jquery.default)(document).one('click', click);
        }
        if (visible) {
          _this.set('animate', true);
        } else {
          _this.set('animate', false);
        }
        if (visible && _this.onShown) {
          _this.onShown();
        }
        _this.persistToLocalStorage();
      }();
    }).restartable(),
    persistKey() {
      return `toggle-state.${this.persist}`;
    },
    persistToLocalStorage() {
      if (this.persist) {
        localStorage.setItem(this.persistKey(), this.visible);
      }
    }
  });
});