define("ember-i18n/utils/add-translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addTranslations;
  function addTranslations(locale, newTranslations, owner) {
    const key = `locale:${locale}/translations`;
    let factory = owner.factoryFor(key);
    let existingTranslations = factory && factory.class;
    if (existingTranslations == null) {
      existingTranslations = {};
      owner.register(key, existingTranslations);
    }
    Object.assign(existingTranslations, newTranslations);
  }
});