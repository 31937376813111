define("ember-line-clamp/templates/components/line-clamp", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each _textLines as |line|}}
    {{#if line.lastLine}}
      <span class="lt-line-clamp__line lt-line-clamp__line--last">
        {{unbound line.text}}
        {{~#if line.needsEllipsis~}}
          <span class="lt-line-clamp__ellipsis"><div class="lt-line-clamp__dummy-element">{{unbound ellipsis}}</div>
          {{!-- Use div to wrap the ellipsis up so that the see more button can work after google translates the page (Issue #48)--}}
            {{#if _showMoreButton}}
              <a data-test-line-clamp-show-more-button="true" href="#" role="button" id="line-clamp-show-more-button" aria-expanded="false" aria-label={{if seeMoreA11yText (unbound seeMoreA11yText) false}} class="lt-line-clamp__more" onclick={{action "toggleTruncate"}}>{{unbound seeMoreText}}</a>
            {{/if}}
          </span>
        {{~/if~}}
      </span>
    {{else if line.newLine}}
      <br>
    {{else}}
      <span class="lt-line-clamp__line">{{unbound line.text}}</span>
    {{/if}}
  {{else if _strippedText}}
    {{_strippedText}}
  {{else}}
    <span class="lt-line-clamp__raw-line">{{text}}</span>
  {{/each}}
  
  {{#if _showLessButton}}
    {{#unless _truncated}}
      {{#if _expanded}}
        <span><a data-test-line-clamp-show-less-button="true" id="line-clamp-show-less-button"  href="#" role="button" aria-expanded="true" aria-label={{if seeLessA11yText (unbound seeLessA11yText) false}} class="lt-line-clamp__less" onclick={{action "toggleTruncate"}}>{{unbound seeLessText}}</a></span>
      {{/if}}
    {{/unless}}
  {{/if}}
  */
  {
    "id": "CikaelYD",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[31,2]],null]],null],null,[[[41,[30,1,[\"lastLine\"]],[[[1,\"    \"],[10,1],[14,0,\"lt-line-clamp__line lt-line-clamp__line--last\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[[30,1,[\"text\"]]],null]],[41,[30,1,[\"needsEllipsis\"]],[[[10,1],[14,0,\"lt-line-clamp__ellipsis\"],[12],[10,0],[14,0,\"lt-line-clamp__dummy-element\"],[12],[1,[28,[35,4],[[31,5]],null]],[13],[1,\"\\n\"],[41,[31,6],[[[1,\"            \"],[10,3],[14,6,\"#\"],[14,\"role\",\"button\"],[14,1,\"line-clamp-show-more-button\"],[14,\"aria-expanded\",\"false\"],[15,\"aria-label\",[52,[31,7],[28,[37,4],[[31,7]],null],false]],[14,0,\"lt-line-clamp__more\"],[15,\"onclick\",[28,[37,8],[[30,0],\"toggleTruncate\"],null]],[12],[1,[28,[35,4],[[31,9]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13]],[]],null],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"newLine\"]],[[[1,\"    \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"lt-line-clamp__line\"],[12],[1,[28,[35,4],[[30,1,[\"text\"]]],null]],[13],[1,\"\\n  \"]],[]]]],[]]]],[1]],[[[41,[31,10],[[[1,\"  \"],[1,[34,10]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"lt-line-clamp__raw-line\"],[12],[1,[34,11]],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"\\n\"],[41,[31,12],[[[41,[51,[31,14]],[[[41,[31,15],[[[1,\"      \"],[10,1],[12],[10,3],[14,1,\"line-clamp-show-less-button\"],[14,6,\"#\"],[14,\"role\",\"button\"],[14,\"aria-expanded\",\"true\"],[15,\"aria-label\",[52,[31,16],[28,[37,4],[[31,16]],null],false]],[14,0,\"lt-line-clamp__less\"],[15,\"onclick\",[28,[37,8],[[30,0],\"toggleTruncate\"],null]],[12],[1,[28,[35,4],[[31,17]],null]],[13],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[\"line\"],false,[\"each\",\"-track-array\",\"_textLines\",\"if\",\"unbound\",\"ellipsis\",\"_showMoreButton\",\"seeMoreA11yText\",\"action\",\"seeMoreText\",\"_strippedText\",\"text\",\"_showLessButton\",\"unless\",\"_truncated\",\"_expanded\",\"seeLessA11yText\",\"seeLessText\"]]",
    "moduleName": "ember-line-clamp/templates/components/line-clamp.hbs",
    "isStrictMode": false
  });
});