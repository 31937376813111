define("ember-i18n/index", ["exports", "ember-i18n/utils/i18n/compile-template", "ember-i18n/services/i18n", "ember-i18n/utils/macro"], function (_exports, _compileTemplate, _i18n, _macro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Service", {
    enumerable: true,
    get: function () {
      return _i18n.default;
    }
  });
  Object.defineProperty(_exports, "compileTemplate", {
    enumerable: true,
    get: function () {
      return _compileTemplate.default;
    }
  });
  Object.defineProperty(_exports, "translationMacro", {
    enumerable: true,
    get: function () {
      return _macro.default;
    }
  });
});