define("ember-i18n/services/i18n", ["exports", "@ember/service", "@ember/object", "@ember/array", "@ember/object/evented", "@ember/utils", "@ember/debug", "@ember/application", "ember-i18n/utils/locale", "ember-i18n/utils/add-translations", "ember-i18n/utils/get-locales"], function (_exports, _service, _object, _array, _evented, _utils, _debug, _application, _locale, _addTranslations, _getLocales) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @public
  // eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend(_evented.default, {
    // @public
    // The user's locale.
    locale: null,
    // @public
    // A list of found locales.
    locales: (0, _object.computed)(_getLocales.default),
    // @public
    //
    // Returns the translation `key` interpolated with `data`
    // in the current `locale`.
    t(key) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const locale = this._locale;
      (false && !(locale) && (0, _debug.assert)("I18n: Cannot translate when locale is null", locale)); // eslint-disable-next-line ember/no-get
      const count = (0, _object.get)(data, 'count');

      // eslint-disable-next-line ember/no-get
      const defaults = (0, _array.makeArray)((0, _object.get)(data, 'default'));
      defaults.unshift(key);
      const template = locale.getCompiledTemplate(defaults, count);
      if (template._isMissing) {
        this.trigger('missing', this.locale, key, data);
      }
      return template(data);
    },
    // @public
    exists(key) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const locale = this._locale;
      (false && !(locale) && (0, _debug.assert)("I18n: Cannot check existance when locale is null", locale)); // eslint-disable-next-line ember/no-get
      const count = (0, _object.get)(data, 'count');
      const translation = locale.findTranslation((0, _array.makeArray)(key), count);
      return (0, _utils.typeOf)(translation.result) !== 'undefined' && !translation.result._isMissing;
    },
    // @public
    addTranslations(locale, translations) {
      (0, _addTranslations.default)(locale, translations, (0, _application.getOwner)(this));
      this._addLocale(locale);
      if (this.locale.indexOf(locale) === 0) {
        this._locale.rebuild();
      }
    },
    // @private
    _initDefaults: (0, _evented.on)('init', function () {
      let owner = (0, _application.getOwner)(this);
      let ENV = owner.factoryFor('config:environment').class;
      if (this.locale == null) {
        var defaultLocale = (ENV.i18n || {}).defaultLocale;
        if (defaultLocale == null) {
          (false && (0, _debug.warn)('ember-i18n did not find a default locale; falling back to "en".', false, {
            id: 'ember-i18n.default-locale'
          }));
          defaultLocale = 'en';
        }
        this.set('locale', defaultLocale);
      }
    }),
    // @private
    //
    // adds a runtime locale to the array of locales on disk
    _addLocale(locale) {
      this.locales.addObject(locale);
    },
    _locale: (0, _object.computed)('locale', function () {
      const locale = this.locale;
      return locale ? new _locale.default(this.locale, (0, _application.getOwner)(this)) : null;
    })
  });
});