define("toggle-wrap/templates/components/toggle-wrap", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    component=this
    visible=this.visible
    hidden=this.hidden
    animate=this.animate
    toggle=(perform this.toggle null)
    show=(perform this.toggle true)
    hide=(perform this.toggle false)
  )}}
  */
  {
    "id": "UbIrCzvQ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"component\",\"visible\",\"hidden\",\"animate\",\"toggle\",\"show\",\"hide\"],[[30,0],[30,0,[\"visible\"]],[30,0,[\"hidden\"]],[30,0,[\"animate\"]],[28,[37,2],[[30,0,[\"toggle\"]],null],null],[28,[37,2],[[30,0,[\"toggle\"]],true],null],[28,[37,2],[[30,0,[\"toggle\"]],false],null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"perform\"]]",
    "moduleName": "toggle-wrap/templates/components/toggle-wrap.hbs",
    "isStrictMode": false
  });
});