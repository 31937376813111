define("@zestia/ember-select-box/utils/registration/selected-option", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deregisterSelectedOption = deregisterSelectedOption;
  _exports.registerSelectedOption = registerSelectedOption;
  function registerSelectedOption(selectBox, option) {
    selectBox.pendingSelectedOption.push(option);
    scheduleUpdateSelectedOptions(selectBox);
  }
  function deregisterSelectedOption(selectBox, option) {
    selectBox.pendingSelectedOption.splice(selectBox.pendingSelectedOption.indexOf(option), 1);
    scheduleUpdateSelectedOptions(selectBox);
  }
  function scheduleUpdateSelectedOptions(selectBox) {
    (0, _runloop.scheduleOnce)('afterRender', selectBox, updateSelectedOptions, selectBox);
  }
  function updateSelectedOptions(selectBox) {
    if (selectBox.isDestroying) {
      return;
    }
    setSelectedOptions(selectBox, selectBox.pendingSelectedOption);
  }
  function setSelectedOptions(selectBox, selectedOptions) {
    const elements = [...selectBox.element.querySelectorAll('.select-box__selected-option')];
    const sort = (a, b) => elements.indexOf(a.element) - elements.indexOf(b.element);
    selectBox.selectedOption = [...selectedOptions].sort(sort);
  }
});