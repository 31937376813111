define("@zestia/ember-select-box/components/native-select-box/index", ["exports", "@ember/component", "@glimmer/component", "@zestia/ember-select-box/components/native-select-box/option/index", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/registration/option", "@zestia/ember-select-box/utils/registration/components", "@zestia/ember-select-box/utils/shared/value", "@zestia/ember-select-box/utils/native-select-box/value", "@zestia/ember-select-box/utils/shared/api", "@zestia/ember-select-box/utils/shared/ready", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _index, _element, _option, _components, _value, _value2, _api, _ready, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-configure no-curly-component-invocation {"allow": ["this.registerComponents"]}  }}
  {{-register-components
    this
    Option=(component
      (ensure-safe-component this.NativeSelectBoxOption)
      selectBox=this
      _onInsert=this.handleInsertOption
      _onDestroy=this.handleDestroyOption
    )
  }}
  <select
    class="select-box"
    multiple={{this.isMultiple}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{did-update this.handleUpdatedValue @value}}
    {{will-destroy this.handleDestroyElement}}
    {{on "change" this.handleChange}}
  >
    {{~yield this.api~}}
  </select>
  */
  {
    "id": "dDJgyrij",
    "block": "[[[1,[28,[35,0],[[30,0]],[[\"Option\"],[[50,[28,[37,2],[[30,0,[\"NativeSelectBoxOption\"]]],null],0,null,[[\"selectBox\",\"_onInsert\",\"_onDestroy\"],[[30,0],[30,0,[\"handleInsertOption\"]],[30,0,[\"handleDestroyOption\"]]]]]]]]],[1,\"\\n\"],[11,\"select\"],[24,0,\"select-box\"],[16,\"multiple\",[30,0,[\"isMultiple\"]]],[17,1],[4,[38,3],[[30,0,[\"handleInsertElement\"]]],null],[4,[38,4],[[30,0,[\"handleUpdatedValue\"]],[30,2]],null],[4,[38,5],[[30,0,[\"handleDestroyElement\"]]],null],[4,[38,6],[\"change\",[30,0,[\"handleChange\"]]],null],[12],[18,3,[[30,0,[\"api\"]]]],[13]],[\"&attrs\",\"@value\",\"&default\"],false,[\"-register-components\",\"component\",\"ensure-safe-component\",\"did-insert\",\"did-update\",\"will-destroy\",\"on\",\"yield\"]]",
    "moduleName": "@zestia/ember-select-box/components/native-select-box/index.hbs",
    "isStrictMode": false
  });
  let NativeSelectBox = _exports.default = (_class = class NativeSelectBox extends _component2.default {
    get api() {
      return (0, _api.default)(this, [
      // Components
      'Option',
      // Properties
      'element', 'isFulfilled', 'isMultiple', 'isPending', 'isRejected', 'isSettled', 'value',
      // Actions
      'select', 'update']);
    }
    get isMultiple() {
      return this.args.multiple;
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "element", null);
      _defineProperty(this, "previousValue", null);
      _defineProperty(this, "stableAPI", {});
      _defineProperty(this, "valueId", 0);
      _initializerDefineProperty(this, "isFulfilled", _descriptor, this);
      _initializerDefineProperty(this, "isPending", _descriptor2, this);
      _initializerDefineProperty(this, "isReady", _descriptor3, this);
      _initializerDefineProperty(this, "isRejected", _descriptor4, this);
      _initializerDefineProperty(this, "isSettled", _descriptor5, this);
      _initializerDefineProperty(this, "value", _descriptor6, this);
      // Component classes
      _defineProperty(this, "NativeSelectBoxOption", _index.default);
      // Component declarations
      _defineProperty(this, "Option", null);
      // Component instances
      _initializerDefineProperty(this, "option", _descriptor7, this);
      _defineProperty(this, "pendingOption", []);
      _defineProperty(this, "registerComponents", (0, _components.registerComponents)(this));
      (0, _value.receiveValue)(this);
      (0, _ready.ready)(this);
    }
    handleInsertElement(element) {
      (0, _element.registerElement)(this, element);
    }
    handleUpdatedValue() {
      (0, _value.receiveValue)(this);
    }
    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
    }
    handleInsertOption(option) {
      (0, _option.registerOption)(this, option);
    }
    handleDestroyOption(option) {
      (0, _option.deregisterOption)(this, option);
    }
    handleChange() {
      (0, _value2.selectValue)(this);
    }
    select(value) {
      return (0, _value.selectValue)(this, value);
    }
    update(value) {
      return (0, _value.updateValue)(this, value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReady", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "option", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleUpdatedValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdatedValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInsertOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NativeSelectBox);
});