define("@zestia/ember-select-box/utils/shared/value", ["exports", "@zestia/ember-select-box/utils/component/value", "@ember/array"], function (_exports, _value, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.receiveValue = receiveValue;
  _exports.selectValue = selectValue;
  _exports.selectedValue = selectedValue;
  _exports.updateValue = updateValue;
  const {
    freeze
  } = Object;
  function receiveValue(selectBox) {
    updateValue(selectBox, selectBox.args.value);
  }
  function updateValue(selectBox, value) {
    return (0, _value.resolveValue)(selectBox, value, processValue).then(() => updatedValue(selectBox));
  }
  function selectValue(selectBox, value) {
    return updateValue(selectBox, value).then(() => selectedValue(selectBox));
  }
  function processValue(selectBox, value) {
    if (selectBox.isMultiple) {
      return freeze((0, _array.makeArray)(value).slice());
    }
    return value;
  }
  function updatedValue(selectBox) {
    var _selectBox$args$onUpd, _selectBox$args;
    if (selectBox.value === selectBox.previousValue) {
      return;
    }
    selectBox.previousValue = selectBox.value;
    (_selectBox$args$onUpd = (_selectBox$args = selectBox.args).onUpdate) === null || _selectBox$args$onUpd === void 0 || _selectBox$args$onUpd.call(_selectBox$args, selectBox.api);
  }
  function selectedValue(selectBox) {
    var _selectBox$args$onSel, _selectBox$args2;
    (_selectBox$args$onSel = (_selectBox$args2 = selectBox.args).onSelect) === null || _selectBox$args$onSel === void 0 || _selectBox$args$onSel.call(_selectBox$args2, selectBox.value, selectBox.api);
  }
});