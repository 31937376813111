define("@zestia/ember-select-box/components/select-box/selected-option/index", ["exports", "@ember/component", "@glimmer/component", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/shared/id", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _lifecycle, _element, _id, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-positive-tabindex no-down-event-binding no-element-event-actions }}
  {{!
  Issue affects attributes `role`, `onclick`, `onmousedown`, `tabindex`
  https://github.com/emberjs/rfcs/issues/497
  }}
  <div
    class="select-box__selected-option"
    id={{this.id}}
    role={{@role}}
    onclick={{@onclick}}
    onmousedown={{@onmousedown}}
    tabindex={{@tabindex}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{will-destroy this.handleDestroyElement}}
  >
    {{~yield~}}
  </div>
  */
  {
    "id": "DAVcURmP",
    "block": "[[[11,0],[24,0,\"select-box__selected-option\"],[16,1,[30,0,[\"id\"]]],[16,\"role\",[30,1]],[16,\"onclick\",[30,2]],[16,\"onmousedown\",[30,3]],[16,\"tabindex\",[30,4]],[17,5],[4,[38,0],[[30,0,[\"handleInsertElement\"]]],null],[4,[38,1],[[30,0,[\"handleDestroyElement\"]]],null],[12],[18,6,null],[13]],[\"@role\",\"@onclick\",\"@onmousedown\",\"@tabindex\",\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "@zestia/ember-select-box/components/select-box/selected-option/index.hbs",
    "isStrictMode": false
  });
  let SelectBoxSelectedOption = _exports.default = (_class = class SelectBoxSelectedOption extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "element", null);
    }
    get id() {
      return (0, _id.default)(this);
    }
    handleInsertElement(element) {
      (0, _lifecycle._insertComponent)(this);
      (0, _element.registerElement)(this, element);
    }
    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
      (0, _lifecycle._destroyComponent)(this);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectBoxSelectedOption);
});