define("@zestia/ember-select-box/utils/registration/option", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deregisterOption = deregisterOption;
  _exports.registerOption = registerOption;
  function registerOption(selectBox, option) {
    selectBox.pendingOption.push(option);
    scheduleUpdateOptions(selectBox);
  }
  function deregisterOption(selectBox, option) {
    selectBox.pendingOption.splice(selectBox.pendingOption.indexOf(option), 1);
    scheduleUpdateOptions(selectBox);
  }
  function scheduleUpdateOptions(selectBox) {
    (0, _runloop.scheduleOnce)('afterRender', selectBox, updateOptions, selectBox);
  }
  function updateOptions(selectBox) {
    if (selectBox.isDestroying) {
      return;
    }
    setOptions(selectBox, selectBox.pendingOption);
  }
  function setOptions(selectBox, options) {
    const elements = [...selectBox.element.querySelectorAll('.select-box__option')];
    const sort = (a, b) => elements.indexOf(a.element) - elements.indexOf(b.element);
    selectBox.option = [...options].sort(sort);
  }
});