define("@zestia/ember-select-box/utils/native-select-box/value", ["exports", "@zestia/ember-select-box/utils/shared/value", "@zestia/ember-select-box/utils/native-select-box/selection"], function (_exports, _value, _selection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.selectValue = selectValue;
  function selectValue(selectBox) {
    const value = (0, _selection.getSelectedValue)(selectBox);
    return (0, _value.selectValue)(selectBox, value);
  }
});