define("ember-i18n/utils/macro", ["exports", "@ember/debug", "@ember/object"], function (_exports, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createTranslatedComputedProperty;
  const keys = Object.keys;

  // @public
  function createTranslatedComputedProperty(key) {
    let interpolations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const dependencies = ['i18n.locale'].concat(values(interpolations));
    return (0, _object.computed)(...dependencies, 'i18n', function () {
      const i18n = this.i18n;
      (false && !(i18n) && (0, _debug.assert)(`Cannot translate ${key}. ${this} does not have an i18n.`, i18n));
      return i18n.t(key, mapPropertiesByHash(this, interpolations));
    });
  }
  function values(object) {
    return keys(object).map(key => object[key]);
  }
  function mapPropertiesByHash(object, hash) {
    const result = {};
    keys(hash).forEach(function (key) {
      result[key] = (0, _object.get)(object, hash[key]);
    });
    return result;
  }
});